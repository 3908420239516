<template>
    <div class="leader-feedback">
        <div class="leader-feedback-content">
            <div class="leader-feedback-title">
                <div class="table-title">Tracking List周报回复数据</div>
                <div class="leader-feedback-top">
                    <div class="date-wrap triangle-drop" :class="isFocusDate?'triangle-drop-up':'triangle-drop-down'">
                        <el-date-picker
                            v-model="date"
                            type="week"
                            placeholder="选择日期"
                            size="small"
                            :editable="false"
                            align="center"
                            format="yyyy 第 WW 周"
                            @change="handleDate"
                            @focus="handleFocusDate"
                            @blur="handleBlurDate"
                            :clearable="false"
                            :picker-options="expireTimeOption"
                            popper-class="performance-data-range">
                        </el-date-picker>
                    </div>
                    <div v-if="isAdministrator" class="employee-select-wrap triangle-drop" :class="isFocusTeam?'triangle-drop-up':'triangle-drop-down'" v-loading="firmTeamLoading">
                        <el-dropdown @command="handleToggleTeam" trigger="click" @visible-change="handleExpand" placement="bottom">
                            <span class="el-dropdown-link">
                                {{teamName || '一级团队'}}
                            </span>
                            <el-dropdown-menu v-if="teamList.length > 0" class="firm-team-dropdown" slot="dropdown">
                                <el-dropdown-item command="-1">一级团队</el-dropdown-item>
                                <el-dropdown-item v-for="team in teamList" :key="team.firstLevelTeamId" :command="team">{{team.firstLevelTeamName}}</el-dropdown-item>
                            </el-dropdown-menu>
                            <el-dropdown-menu v-else class="firm-team-dropdown" slot="dropdown">
                                <el-dropdown-item>暂无数据</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
            
            <div class="leader-feedback-table">
                <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    @cell-mouse-enter="handleCellMouseEnter"
                    tooltip-effect="light">

                    <el-table-column
                        v-for="item in tableTitle"
                        :key="item.field"
                        :prop="item.field"
                        align="left"
                        :class="item.class"
                        :min-width="item.width"
                        :show-overflow-tooltip="true"
                        :resizable="false"
                        :label="item.label">
                    </el-table-column>
                </el-table>
                <div class="work-table-footer">
                    <el-pagination :current-page="page" :page-sizes="[15, 20, 50]" :page-size="pageSize" class="el-pagination-workTable" layout="total, sizes, prev, pager, next, slot" :total="totalCount" @size-change="handleSizeChange" @current-change="handleCurrentChange">
                        <span class="pagination-text">
                            <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                            <span @click="handlePagerJump">跳转</span>
                        </span>
                    </el-pagination>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { trackingListReport as trackingListReportUrl } from '#/js/config/javaApi.json';
    import { currency as currencyFilter } from '#/js/filters/number.js';
    import moment from 'moment';
    import talentPoolService from '#/js/service/talentPoolService.js';

    export default {
        name: "LeaderFeedback",
        components: {
        },
        data() {
            return {
                loading: false,
                firmTeamLoading: false,
                date: '',
                totalCount: 0,
                page: 1,
                pageSize: 15,
                pagerJump: 0,
                startDate: '',
                endDate: '',
                userIds: [],
                tableTitle: [
                    {
                        field: 'firstLevelTeamName',
                        label: '一级团队',
                        class: '',
                        width: '188'
                    },
                    {
                        field: 'secondLevelTeamName',
                        label: '二级团队',
                        class: '',
                        width: '150'
                    },
                    {
                        field: 'trackingListAddCount',
                        label: 'TrackingList新增数量',
                        class: '',
                        width: '226'
                    },
                    {
                        field: 'inquiryCount',
                        label: '寻访数量',
                        class: '',
                        width: '172'
                    },
                    {
                        field: 'feedBackCount',
                        label: 'Leader回复数量',
                        class: '',
                        width: '195'
                    },
                    {
                        field: 'feedBackPercent',
                        label: 'Leader回复率',
                        class: '',
                        width: '176'
                    }
                ],
                tableData: [],
                isFocusDate: false,//用于控制日期的三角
                expireTimeOption: {
                    disabledDate(date) {
                        return date.getTime() >= (new Date(new Date().toDateString()).getTime() + 24*60*60*1000);
                    },
                    firstDayOfWeek: 1
                },
                isFocusTeam: false,
                teamList: [],
                teamId: '',
                teamName: '',
            }
        },
        computed: {
            isAdministrator() {
                return this.$store.state.user.userInfo.isAdministrator;
            },
            firmId() {
                return this.$store.state.user.userInfo.firmId;
            },
            isCaculateOpen() {
                return this.$store.state.user.userInfo.isOpenPerformanceCalculation;
            },
            dateRange() {
                if(this.startDate && this.endDate) {
                    return [this.startDate, this.endDate];
                }else {
                    let startDate = moment().subtract(0, 'months').format('YYYY-MM') + '-01';
                    let endDate = moment(startDate).add(1, 'month').subtract(1, 'days').format('YYYY-MM-DD');
                    return [startDate, endDate];
                }
            }

        },
        filters: {
            numPointFilter(val) {
                return val.replace('.00', '');
            },
            dataFilter(val) {
                return moment(val).format('YYYY-MM-DD HH:mm:SS')
            }
        },
        created() {
        },
        mounted() {
            talentPoolService.getTeamInfo().then((res) => {
                if(res.isAdmin || res.isFirstLevelTeamLeader) {
                     this.date = this.startDate = moment().subtract(1,'weeks').startOf('weeks').format('YYYY-MM-DD');
                    this.endDate = moment(this.date).add(6, 'days').format('YYYY-MM-DD');
                    if(this.isAdministrator) {
                        this.getFirmTeams();
                    }
                    this.getData();
                } else {
                    shortTips("权限不足，请联系管理员")
                    setTimeout(() => {
                        location.href = "/Headhunting/#/";
                    }, 2000)
                }
            })

            // if(!this.$store.state.user.userInfo.isAdminOrTeamLeader) {
            //     shortTips("权限不足，请联系管理员")
            //     setTimeout(() => {
            //         location.href = "/Headhunting/#/";
            //     }, 2000)
            // } else {
            //     this.date = this.startDate = moment().subtract(1,'weeks').startOf('weeks').format('YYYY-MM-DD');
            //     this.endDate = moment(this.date).add(6, 'days').format('YYYY-MM-DD');
            //     // this.date = this.startDate = moment().subtract(0, 'months').format('YYYY-MM') + '-01';
            //     // this.endDate = moment(this.startDate).add(1, 'month').subtract(1, 'days').format('YYYY-MM-DD');
            //     if(this.isAdministrator) {
            //         this.getFirmTeams();
            //     }
            //     this.getData();
            // }
        },
        methods: {
            handleToggleTeam(team) {
                if(team === undefined) {
                    return false;
                }
                if(team == -1) {
                    this.teamId = team.firstLevelTeamId;
                    this.teamName = team.firstLevelTeamName;
                }else {
                    this.teamId = team.firstLevelTeamId;
                    this.teamName = team.firstLevelTeamName;
                }
                this.page = 1;
                this.getData();
            },
            getFirmTeams() {
                this.firmTeamLoading = true;
                _request({
                    url: trackingListReportUrl.tracking_list_report_teams,
                    method: 'POST',
                    baseURL: 'LbdJavaApi',
                    javaProject: 'performance',
                    data: {
                        starDate: this.startDate,
                        endDate: this.endDate
                    }
                }).then(res => {
                    this.firmTeamLoading = false;
                    this.teamList = res || [];
                }).catch(err => {
                    this.firmTeamLoading = false;
                })
            },
            getData() {
                this.loading = true;
                let data = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    offset: (this.page - 1) * this.pageSize,
                    pageSize: this.pageSize
                }
                if(this.teamId) {
                    data.teamIds = [this.teamId];
                }
                _request({
                    method: 'POST',
                    url: trackingListReportUrl.tracking_list_report_list,
                    baseURL: 'LbdJavaApi',
                    javaProject: 'performance',
                    data: data
                }).then(res =>{
                    this.totalCount = res.count;
                    let list = res.data || [];
                    list.forEach(item => {
                        if(item.feedBackPercent) {
                            item.feedBackPercent = Math.round(item.feedBackPercent * 10000) / 100 + '%';
                        }else {
                            item.feedBackPercent = '0%';
                        }
                    })
                    this.tableData = list;
                    this.loading = false;
                }).catch(err =>{
                    this.loading = false;
                })
            },
            handlePagerJump() {
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.totalCount/this.pageSize);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.page = currentPager;
                    this.getData();
                }
            },
            handleCurrentChange(val){
                this.page = val;
                this.getData();
            },
            handleSizeChange(val){
                this.page = 1;
                this.pageSize = val;
                this.getData();
            },
            handleCellMouseEnter(row, column, cell, event, flag) {
                let popperEleArr = document.getElementsByClassName('el-tooltip__popper is-light');
                if(popperEleArr && popperEleArr.length > 0) {
                    Array.prototype.forEach.call(popperEleArr, (popperEle) => {
                        popperEle.innerText = cell.innerText;
                        if(popperEle.innerText.indexOf('\n') > -1) {
                            let html = popperEle.innerText.replace('\n', '<br />');
                            popperEle.innerHTML = popperEle.innerHTML.replace(popperEle.innerText, html);
                        }
                    })
                }else {
                    if(!flag) {
                        setTimeout(() =>{
                            this.handleCellMouseEnter(row, column, cell, event, true);
                        }, 100)
                    }
                }
            },
            handleDate(value) {
                value = moment(value).subtract(1, 'days').format('YYYY-MM-DD');
                this.startDate = value;
                this.endDate = moment(value).add(6, 'days').format('YYYY-MM-DD');
                this.page = 1;
                this.teamId = '';
                this.teamName = '';
                if(this.isAdministrator) {
                    this.getFirmTeams();
                }
                this.getData();
            },
            handleFocusDate() {
                this.isFocusDate = true;
            },
            handleBlurDate() {
                this.isFocusDate = false;
            },
         
            handleExpand(value){
                this.isFocusTeam = value;
            }
        }
    }
</script>

<style lang="scss" scope>
    .leader-feedback {
        // background-color: #f9f9f9;
        padding: 20px;
        height: 100%;
        overflow-y: auto;

        &-content {
            padding-bottom: 38px;
            background-color: #fff;
            border-radius: 8px;
            min-height: calc(100vh - 90px);
        }

        &-title {
            display: flex;
            justify-content: space-between;
            line-height: 32px;
            padding: 30px 30px 0;
            margin-bottom: 8px;

            .table-title {
                font-size: 28px;
                color: #666;
                line-height: 32px;
            }

            & > span {
                color: $primary;
            }

            &-btn {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 32px;
            }
        }

        &-top {
            display:flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
        }

        .el-table th, .el-table td {
            padding: 8px 0;
        }

        .work-table-footer {
            margin: 35px 35px 0 0
        }

        .date-type-dropdown.el-dropdown {
            margin-right: 20px;
            height: 32px;
            width: 70px;
            // border-radius: 16px;
            border: 1px solid #d3d3d3;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 30px;
            font-size: 14px;
            color: #76838f;
            cursor: pointer;
            background-color: #fff;

            &:hover {
                background-color: #38BC9D;
                border-color:#38BC9D;
                color: #fff;
            }

            .el-icon--right {
                color: transparent;
            }
        }

        .cell-share-data {
            width: 136px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .table-header-tip-cell .table-header-tip-icon {
            top: 0;
        }

        .el-table thead th {
            // border: 0 none;
            // background-color: #f8f8f8;
            background-color: #F0F4F7;
            color: #666;
            font-weight: bold;

            .cell {
                padding: 0 20px;
            }
        }

        .el-table--striped .el-table__body tr.el-table__row--striped td {
            background-color: #f8f8f8;
        }

        .el-table td {
            // border: 0 none;
        }

        .date-wrap {
            position: relative;
            cursor: pointer;
            width: 140px;
            
            .el-date-editor {
                height: 32px;
                width: 140px;
                .el-input__inner {
                    // border-radius: 16px;
                    padding-left: 20px;
                    padding-right: 20px;
                    cursor: pointer;
                }
                &:hover {
                    .el-input__inner {
                        border-color:#38BC9D;
                        background: #38BC9D;
                        color: #fff;
                    }
                }
                .el-input__prefix, .el-input__suffix {
                    display: none;
                }
            }
        }
        .triangle-drop{
            &:after{
                position: absolute;
                display: block;
                content: '';
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
                pointer-events: none;
            }
            &-down{
                &:after{
                    border-top: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-top: 12px solid #fff;
                    }
                }

            }
            &-up{
                &:after{
                    border-bottom: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-bottom: 12px solid #fff;
                    }
                }
            }
        }

        .employee-select-wrap {
            position: relative;
            margin-left: 20px;
            width: 116px;
            &:hover {
                .el-dropdown {
                    background: #38BC9D;
                    border-color: #38BC9D;
                    .el-dropdown-link {
                        color: #fff;
                    }
                }
            }
            .el-dropdown {
                border: 1px solid #ddd;
                // border-radius: 16px;
                height: 32px;
                line-height: 32px;
            }
            .el-dropdown-link {
                display: inline-block;
                width: 116px;
                padding: 0 36px 0 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: top;
            }
            &.triangle-drop-up {
                .el-dropdown {
                    background: #38BC9D;
                    border-color: #38BC9D;
                    .el-dropdown-link {
                        color: #fff;
                    }
                }
                &::after {
                    border-bottom: 12px solid #fff;
                }
            }
        }
    }
</style>
<style lang="scss">
    .firm-team-dropdown {
        max-height: 500px;
        overflow-y: scroll;
    }
</style>
